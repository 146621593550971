/** @jsxImportSource preact */
import SiteDialog from '$/components/SiteDialog'
import { globalStore, updateAlertDialog } from '$/store'
import { useStore } from '@nanostores/preact'

export default function SiteAlertDialog() {
  const { alertMessage } = useStore(globalStore)

  const close = () => updateAlertDialog(null)

  return (
    <>
      {alertMessage && (
        <SiteDialog
          role="alertdialog"
          closeButtonPosition="bottom"
          close={close}
          class="u-max-w-md u-text-center"
          closeButtonAutoFocus={true}
        >
          <div dangerouslySetInnerHTML={{ __html: alertMessage }} />
        </SiteDialog>
      )}
    </>
  )
}
